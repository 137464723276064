import { HttpClient } from '@angular/common/http';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
var UserService = /** @class */ (function () {
    function UserService(http) {
        this.http = http;
        this.baseUrl = 'api/users';
    }
    UserService.prototype.assignUserToApplication = function (data) {
        return this.http.post(this.baseUrl + "/" + data.userEmail + "/appRoleAssignments", data);
    };
    UserService.prototype.removeUserFromRole = function (userId, roleAssignmentId) {
        return this.http.delete(this.baseUrl + "/" + userId + "/appRoleAssignments/" + roleAssignmentId);
    };
    UserService.prototype.getAdministratorsForApplication = function (applicationId) {
        return this.http.get("api/admins/" + applicationId);
    };
    UserService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function UserService_Factory() { return new UserService(i0.ɵɵinject(i1.HttpClient)); }, token: UserService, providedIn: "root" });
    return UserService;
}());
export { UserService };
