import { Router } from '@angular/router';
import { Component } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { UtilsService } from 'src/app/services/utils.service';

@Component({
    selector: 'app-form-dialog',
    templateUrl: './form-dialog.component.html',
    styleUrls: ['./form-dialog.component.scss']
})
export class FormDialogComponent {
    public static route: string = '';

    constructor(
        private router: Router,
        private dialogRef: MatDialogRef<FormDialogComponent>,
        public utilService: UtilsService
    ) {}

    /**
     * @desc go to the following url
     * @param {string} route
     * @memberof FormDialogComponent
     */
    public onYes() {
        this.router.navigateByUrl(FormDialogComponent.route);
        this.utilService.isDirty = false;
        this.dialogRef.close();
    }

    /**
     * @returns
     * @desc do nothing
     * @memberof FormDialogComponent
     */
    public onCancel() {
        this.dialogRef.close();
    }
}
