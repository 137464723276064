import { MsalService } from './msal.service';
import { Injectable } from '@angular/core';
import {
  HttpInterceptor,
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpErrorResponse
} from '@angular/common/http';
import { Observable, throwError, from } from 'rxjs';
import { catchError, switchMap, tap } from 'rxjs/operators';

@Injectable()
export class AuthenticationInterceptor implements HttpInterceptor {
  constructor(private msalService: MsalService) {}

  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    if (
      req.url.indexOf('assets/') >= 0 ||
      req.url.indexOf('configuration') >= 0
    ) {
      return next.handle(req);
    }

    return from(this.msalService.acquireTokenSilent()).pipe(
      switchMap(token => {
        if (token) {
          req = req.clone({
            setHeaders: {
              Authorization: `Bearer ${token}`
            }
          });
        } // if (token)

        // set headers for sending and retrieving in json format
        if (!req.headers.has('Content-Type')) {
          req = req.clone({
            headers: req.headers.set('Content-Type', 'application/json')
          });
        }

        req = req.clone({
          headers: req.headers.set('Accept', 'application/json')
        });

        return next
          .handle(req)
          .pipe(tap(() => {}, response => this.handleErrorResponse(response)));
      })
    );
  }

  private handleErrorResponse(error: any) {
    if (error instanceof HttpErrorResponse) {
      if (error.status === 401) {
        this.msalService.login();
      }
    }
  }
}
