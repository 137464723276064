import { AbstractControl } from '@angular/forms';

export function ValidateWhiteSpace(control: AbstractControl) {
    if (control.value != null) {
        if (control.value.trim() === '') {
            return { invalidInput: true };
        }
    }

    return null;
}
