import { Injectable } from '@angular/core';

import * as Msal from 'msal';
import { User } from 'msal/lib-commonjs/User';
import { SettingsProvider } from '../../settingsprovider';


@Injectable()
export class MsalService {
    private tenantConfig: any;

    private clientApplication: Msal.UserAgentApplication;

    constructor(private settingsProvider: SettingsProvider) {
        settingsProvider.configLoaded.subscribe(() => {
            this.tenantConfig = this.settingsProvider.configuration;
            this.clientApplication = new Msal.UserAgentApplication(
                this.tenantConfig.clientId,
                this.tenantConfig.authoritySignIn,
                this.authCallback.bind(this),
                // use local storage over session storage
                // to allow the user to work with multiple tabs.
                {
                    validateAuthority: false,
                    cacheLocation: 'localStorage',
                    navigateToLoginRequestUrl: false,
                    redirectUri:
                        window.location.protocol + '//' + window.location.host,
                    loadFrameTimeout: 6000
                }
            );
        });
    }

    private setAuthority(authority: string) {
        if (this.clientApplication.authority !== authority) {
            this.clientApplication.authority = authority;
        }
    }

    public login(): void {
        // the desginId parameter can be used to control the look
        // of the policy.
        console.log('login...');
        if (this.clientApplication.loginInProgress()) {
            return;
        }

        this.setAuthority(this.tenantConfig.authoritySignIn);
        this.clientApplication.loginRedirect(this.tenantConfig.b2cScopes, null);
    }

    // This gets a cached or refreshed token from MSAL.
    // If no token is available, the promise is
    // rejected.
    public acquireTokenSilent(): Promise<string> {
        console.log('acquireTokenSilent...');
        if (this.clientApplication.loginInProgress()) {
            return new Promise<string>(() => {});
        }

        this.setAuthority(this.tenantConfig.authoritySignIn);
        return this.clientApplication
            .acquireTokenSilent(this.tenantConfig.b2cScopes)
            .then(
                token => {
                    return new Promise<string>((resolve, reject) => {
                        resolve(token);
                    });
                },
                error => {
                    if (error.indexOf('AADB2C90077') >= 0) {
                        this.clientApplication.logout();
                    }
                    return new Promise<string>((resolve, reject) => {
                        resolve(null);
                    });
                }
            );
    }

    logout(): void {
        this.setAuthority(this.tenantConfig.authoritySignIn);
        this.clientApplication.logout();
    }

    isOnline(): boolean {
        return this.clientApplication.getUser() != null;
    }

    public getUser(): User {
        return this.clientApplication.getUser();
    }

    private authCallback(
        errorDesc: any,
        token: any,
        error: any,
        tokenType: any
    ): void {
        if (errorDesc || error) {
            if (errorDesc.indexOf('AADB2C90118') > -1) {
                const userAgentApplication = window.msal as Msal.UserAgentApplication;
                userAgentApplication.authority = this.tenantConfig.authorityForgotPassword;
                userAgentApplication.loginRedirect(
                    this.tenantConfig.b2cScopes,
                    null
                );
            } else {
                console.log(error + ':' + errorDesc);
            }
        }
    }
}
