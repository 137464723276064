import * as tslib_1 from "tslib";
import { debounceTime } from "rxjs/internal/operators/debounceTime";
import { BaseList } from "src/app/base/base.list";
import { ApplicationService } from "src/app/services/application.service";
import { UtilsService } from "src/app/services/utils.service";
import { OnDestroy, OnInit } from "@angular/core";
import { MatPaginator } from "@angular/material/paginator";
import { MatSort } from "@angular/material/sort";
import { MatTableDataSource } from "@angular/material/table";
import { Router } from "@angular/router";
import { TranslateService } from "@ngx-translate/core";
var DISPLAYED_COLUMNS = [
    'displayName',
    'applicationId',
    'edit'
];
var ApplicationsComponent = /** @class */ (function (_super) {
    tslib_1.__extends(ApplicationsComponent, _super);
    function ApplicationsComponent(appService, router, utils, translate) {
        var _this = _super.call(this, router, './applications', 'displayName') || this;
        _this.appService = appService;
        _this.router = router;
        _this.utils = utils;
        _this.translate = translate;
        _this.initI18nData();
        _this.role = localStorage.getItem('ccmsrole');
        return _this;
    }
    ApplicationsComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.isLoaded = false;
        this.displayedColumns = DISPLAYED_COLUMNS;
        this.applicationSubscription = this.appService
            .getApplications(null)
            .subscribe(function (applications) {
            // set data source related settings
            _this.dataSource = new MatTableDataSource(applications);
            _this.isLoading = false;
            // By default sort the first columns by selected column
            if (applications.length > 0) {
                _this.dataSource.filteredData.sort(_this.sortStrings.bind(_this));
                // pagination and sorting
                _this.dataSource.paginator = _this.paginator;
                _this.dataSource.sort = _this.sort;
                _this.isLoaded = true;
            }
        }, function (error) {
            _this.isLoading = false;
            _this.isLoaded = true;
            _this.utils.displayToast(_this.translations.noDataFound);
        });
        this.initSearchLogic();
    };
    ApplicationsComponent.prototype.initSearchLogic = function () {
        var _this = this;
        this.searchTextSubscription = this.searchTextChanged
            .pipe(debounceTime(500))
            .subscribe(function (value) {
            _this.isLoading = false;
            _this.appService.getApplications(value);
            _this.dataSource.filter = value;
        }, function (error) {
            _this.isLoading = false;
            _this.utils.displayToast(_this.translations.noDataFound);
        });
    };
    ApplicationsComponent.prototype.initI18nData = function () {
        var _this = this;
        this.translate.get('errors').subscribe(function (data) {
            _this.translations = data;
        });
    };
    ApplicationsComponent.prototype.ngOnDestroy = function () {
        this.applicationSubscription.unsubscribe();
    };
    return ApplicationsComponent;
}(BaseList));
export { ApplicationsComponent };
