import { HttpClient } from '@angular/common/http';
import { environment } from '../environments/environment';
import { ReplaySubject } from 'rxjs';
import { tap } from 'rxjs/operators';
var SettingsProvider = /** @class */ (function () {
    function SettingsProvider(http) {
        this.http = http;
        this.configLoaded = new ReplaySubject(1);
    }
    SettingsProvider.prototype.getUrl = function (url) {
        return environment.urlPrefix + url;
    };
    // public loadConfig(): Promise<any> {
    //     console.log('trying to load config...');
    //     const configEndpoint = this.getUrl('api/configuration');
    //     return this.http
    //         .get(configEndpoint)
    //         .toPromise()
    //         .then(res => {
    //             console.log(res);
    //             this.config = res;
    //             this.configLoaded.next(true);
    //             Promise.resolve();
    //         })
    //         .catch(error => {
    //             this.configLoaded.error(error);
    //             Promise.reject(error);
    //         });
    // }
    SettingsProvider.prototype.loadConfiguration = function () {
        var _this = this;
        return this.http
            .get(this.getUrl('api/configuration'))
            .pipe(tap(function (config) {
            _this.config = config;
            _this.configLoaded.next();
        }));
    };
    Object.defineProperty(SettingsProvider.prototype, "configuration", {
        get: function () {
            return this.config;
        },
        enumerable: true,
        configurable: true
    });
    return SettingsProvider;
}());
export { SettingsProvider };
