import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { IInvitation } from '../models/IInvitation';

@Injectable({
    providedIn: 'root'
})
export class InvitationService {
    private baseUrl: string;

    constructor(private http: HttpClient) {
        this.baseUrl = 'api/invitations';
    }

    public inviteUser(data: IInvitation): Observable<any> {
        return this.http.post<any>(this.baseUrl, data);
    }
}
