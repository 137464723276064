import { ValidateWhiteSpace } from "src/app/common/validators/whitespace.validator";
import { IInvitation } from "src/app/models/IInvitation";
import { InvitationService } from "src/app/services/invitation.service";
import { UtilsService } from "src/app/services/utils.service";
import { SettingsProvider } from "src/app/settingsprovider";

import { Component, OnInit } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { Router } from "@angular/router";
import { TranslateService } from "@ngx-translate/core";

@Component({
    selector: 'app-invitations',
    templateUrl: './invitations.component.html',
    styleUrls: ['./invitations.component.scss']
})
export class InvitationsComponent implements OnInit {
    invitationForm: FormGroup;
    // error message displayed above forms
    displayErrorMessage: string = '';
    isLoading: boolean;
    private translations: any;

    private appConfig: any;
    public applicationOptions = new Array<string>();
    public applicationRoles = new Array<string>();

    selectedApplication: string;
    selectedRole: string;
    role: string;
    firstName: string;
    lastName: string;

    constructor(
        private formBuilder: FormBuilder,
        private translateService: TranslateService,
        private invitationService: InvitationService,
        private utilService: UtilsService,
        private settingsProvider: SettingsProvider,
        protected router: Router
    ) {
        this.appConfig = settingsProvider.configuration;
        this.initI18nData();
    }

    ngOnInit() {
        this.initInvitationForm();
        this.role = localStorage.getItem('ccmsrole');

        if (this.role === 'CustomerAdmin') {
            this.router.navigateByUrl('applications');
            return;
        }

        this.applicationOptions = this.getApplicationNames();
    }

    onSubmit() {
        this.isLoading = true;
        if (this.invitationForm.invalid) {
            this.getErrorMessage('errors.invalidApplication');
            this.isLoading = false;
            return;
        }

        const invitation: IInvitation = this.generateInvitation();

        this.invitationService.inviteUser(invitation).subscribe(
            response => {
                this.isLoading = false;
                this.utilService.displayToast(
                    this.translations['successfullInvitation']
                );
                this.ngOnInit();
            },
            error => {
                this.isLoading = false;
                console.log(error);
                if (error.status === 400) {
                    this.utilService.displayToast(this.translations.couldNotInviteBadRequest);
                } else if (error.status === 424 && error.error.includes('BadRequest')) {
                    this.utilService.displayToast(this.translations.dependencyFailureBadRequest);
                } else if (error.status === 424 && error.error.includes('NotFound')) {
                    this.utilService.displayToast(this.translations.dependencyFailureNotFound);
                } else if (error.status === 424 && error.error.includes('InternalServerError')) {
                    this.utilService.displayToast(this.translations.dependencyFailureInternalServerError);
                } else if (error.status === 424) {
                    this.utilService.displayToast(this.translations.dependencyFailureUnexpectedError);
                } else {
                    this.utilService.displayToast(this.translations.failedRequest);
                }
            }
        );
    }

    private generateInvitation() {
        let invitation: IInvitation = {
            email: this.invitationForm.get('email').value,
            applicationName: this.selectedApplication,
            roleName: this.selectedRole,
            firstName: this.invitationForm.get('firstName').value,
            lastName: this.invitationForm.get('lastName').value
        };

        return invitation;
    }

    // get translation for tips messages
    private getErrorMessage(message: any) {
        this.translateService.get(message).subscribe((text: string) => {
            this.displayErrorMessage = text;
        });
    }

    resetErrorMessage() {
        this.displayErrorMessage = '';
    }

    private initI18nData() {
        this.translateService.get('messages').subscribe(data => {
            this.translations = data;
        });
    }

    private initInvitationForm() {
        this.invitationForm = this.formBuilder.group({
            firstName: ['', [Validators.required, ValidateWhiteSpace]],
            lastName: ['', [Validators.required, ValidateWhiteSpace]],
            email: [
                '',
                [Validators.required, ValidateWhiteSpace, Validators.email]
            ]
        });
    }

    onChangeApplicationOption(event, option) {
        if (event.isUserInput) {
            this.selectedApplication = option;
        }

        this.applicationRoles = this.getRoleNamesForSystem(this.selectedApplication, this.role);
    }

    onChangeRole(event, option) {
        if (event.isUserInput) {
            this.selectedRole = option;
        }
    }

    private getApplicationNames() {
        var applicationsNames = new Array<string>();

        for(var inviteSystemIndex in this.appConfig.inviteSystems) {
            applicationsNames.push(this.appConfig.inviteSystems[inviteSystemIndex].displayName);
        }

        return applicationsNames;
    }

    private getRoleNamesForSystem(systemDisplayName, userRole) {
        var roleNames = new Array<string>();

        for(var inviteSystemIndex in this.appConfig.inviteSystems) {
            if (this.appConfig.inviteSystems[inviteSystemIndex].displayName === systemDisplayName) {
                for(var roleIndex in this.appConfig.inviteSystems[inviteSystemIndex].roles) {
                    if (this.appConfig.inviteSystems[inviteSystemIndex].roles[roleIndex].permittedUserRoles.includes(userRole)) {
                        roleNames.push(this.appConfig.inviteSystems[inviteSystemIndex].roles[roleIndex].displayName);
                    }
                }
            }
        }

        return roleNames;
    }
}
