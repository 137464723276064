import { HTTP_INTERCEPTORS, HttpClient, HttpClientModule } from "@angular/common/http";
import { APP_INITIALIZER, NgModule } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { BrowserModule } from "@angular/platform-browser";
import { TranslateLoader, TranslateModule } from "@ngx-translate/core";
import { TranslateHttpLoader } from "@ngx-translate/http-loader";

import { AppComponent } from "./app.component";
import { AppRoutingModule } from "./app.routing";
import { FormDialogComponent } from "./common/form-dialog/form-dialog.component";
import { MaterialModule } from "./material/material.module";
import {
    ApplicationCreationComponent
} from "./routes/applications/application-creation/application-creation.component";
import {
    ApplicationDetailsComponent
} from "./routes/applications/application-details/application-details.component";
import { ApplicationsComponent } from "./routes/applications/applications.component";
import { ForbiddenComponent } from "./routes/forbidden/forbidden.component";
import { InvitationsComponent } from "./routes/invitations/invitations.component";
import { InvitationService } from "./services/invitation.service";
import { UserService } from "./services/user.service";
import { SettingsProvider } from "./settingsprovider";
import { AppInsightService } from "./shared/appinsights/appinsights.service";
import { AuthenticationInterceptor } from "./shared/authentication/api.interceptor";
import { AuthGuard } from "./shared/authentication/auth.guard";
import { MsalService } from "./shared/authentication/msal.service";

export function HttpLoaderFactory(httpClient: HttpClient) {
    return new TranslateHttpLoader(httpClient, './assets/i18n/', '.json');
}

@NgModule({
    declarations: [
        AppComponent,
        ApplicationsComponent,
        ApplicationDetailsComponent,
        FormDialogComponent,
        ApplicationCreationComponent,
        InvitationsComponent,
        ForbiddenComponent
    ],
    imports: [
        BrowserModule,
        MaterialModule,
        AppRoutingModule,
        HttpClientModule,
        FormsModule,
        ReactiveFormsModule,
        TranslateModule.forRoot({
            loader: {
                provide: TranslateLoader,
                useFactory: HttpLoaderFactory,
                deps: [HttpClient]
            }
        })
    ],
    providers: [
        {
            provide: APP_INITIALIZER,
            useFactory: initializeConfiguration,
            deps: [SettingsProvider],
            multi: true
        },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: AuthenticationInterceptor,
            multi: true
        },
        SettingsProvider,
        MsalService,
        AuthGuard,
        UserService,
        InvitationService,
        AppInsightService
    ],
    bootstrap: [AppComponent],
    entryComponents: [FormDialogComponent]
})
export class AppModule {}

export function initializeConfiguration(settingsProvider: SettingsProvider) {
    return (): Promise<any> => {
        return new Promise<any>((resolve, reject) => {
            settingsProvider.loadConfiguration().subscribe(
                () => {
                    resolve();
                },
                () => {
                    reject();
                }
            );
        });
    };
}
