import * as tslib_1 from "tslib";
import { BaseEntityEdit } from "src/app/base/base.entity.edit";
import { ApplicationService } from "src/app/services/application.service";
import { UserService } from "src/app/services/user.service";
import { UtilsService } from "src/app/services/utils.service";
import { OnInit } from "@angular/core";
import { FormBuilder, Validators } from "@angular/forms";
import { MatDialog } from "@angular/material/dialog";
import { ActivatedRoute, Router } from "@angular/router";
import { TranslateService } from "@ngx-translate/core";
var ApplicationDetailsComponent = /** @class */ (function (_super) {
    tslib_1.__extends(ApplicationDetailsComponent, _super);
    function ApplicationDetailsComponent(fb, appService, route, utilService, router, matDialog, translateService, userService) {
        var _this = _super.call(this, matDialog, router, 'applications') || this;
        _this.fb = fb;
        _this.appService = appService;
        _this.route = route;
        _this.utilService = utilService;
        _this.router = router;
        _this.matDialog = matDialog;
        _this.translateService = translateService;
        _this.userService = userService;
        _this.criticalities = ['Undefined', 'C0', 'C1', 'C2', 'C3', 'C4'];
        _this.durations = ['1 year', '2 years'];
        return _this;
    }
    ApplicationDetailsComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.initI18nData();
        this.initApplicationForm();
        this.initPasswordCredentialForm();
        this.initCustomerAdminForm();
        this.initApiAdminForm();
        this.route.params.subscribe(function (params) {
            _this.id = params.id;
            _this.getApplicationByAppId();
            _this.getAdmins();
            _this.role = localStorage.getItem('ccmsrole');
        });
    };
    ApplicationDetailsComponent.prototype.getApplicationByAppId = function () {
        var _this = this;
        this.appService.getApplicationByAppId(this.id).subscribe(function (application) {
            _this.application = application;
            _this.setFormValues(_this.application);
            console.log(_this.application);
        }, function (error) {
            if (error.status === 403) {
                _this.utilService.displayToast(_this.translations.noPermissions);
                _this.goBack();
                return;
            }
            _this.utilService.displayToast(_this.translations.appNotFound);
        });
    };
    ApplicationDetailsComponent.prototype.getAdmins = function () {
        var _this = this;
        this.userService.getAdministratorsForApplication(this.id).subscribe(function (admins) {
            _this.administrators = admins;
            console.log(_this.administrators);
        }, function (error) {
            _this.translateService.get('messages.' + error.error).subscribe(function (text) {
                if (text != error.error) {
                    _this.utilService.displayToast(text);
                }
                else {
                    _this.utilService.displayToast(_this.translations.appNotFound);
                }
            });
        });
    };
    ApplicationDetailsComponent.prototype.assignApiAdmin = function (formDirective) {
        var _this = this;
        var roleAssignment = {
            applicationId: this.id,
            userEmail: this.apiAdminForm.get('email').value,
            azureAdRoleId: this.application.appRoles.find(function (x) { return x.displayName === 'ApiAdmin'; }).roleId
        };
        this.userService.assignUserToApplication(roleAssignment).subscribe(function (_) {
            _this.getAdmins();
            _this.initApiAdminForm();
            formDirective.resetForm();
            _this.apiAdminForm.reset();
        }, function (error) {
            console.log(error);
            if (error.status === 400) {
                _this.getErrorMessage('errors.couldNotAddBadRequest');
                _this.utilService.displayToast(_this.translations.couldNotAddBadRequest);
            }
            else if (error.status === 404) {
                _this.getErrorMessage('errors.userDoesNotExist');
                _this.utilService.displayToast(_this.translations.userDoesNotExist);
            }
            else {
                _this.getErrorMessage('errors.couldNotAddFailedRequest');
                _this.utilService.displayToast(_this.translations.couldNotAddFailedRequest);
            }
        });
    };
    ApplicationDetailsComponent.prototype.assignCustomerAdmin = function (formDirective) {
        var _this = this;
        var roleAssignment = {
            applicationId: this.id,
            userEmail: this.customerAdminForm.get('email').value,
            azureAdRoleId: this.application.appRoles.find(function (x) { return x.displayName === 'CustomerAdmin'; }).roleId
        };
        this.userService.assignUserToApplication(roleAssignment).subscribe(function (_) {
            _this.getAdmins();
            _this.initCustomerAdminForm();
            formDirective.resetForm();
            _this.customerAdminForm.reset();
        }, function (error) {
            console.log(error);
            if (error.status === 400) {
                _this.getErrorMessage('errors.couldNotAddBadRequest');
                _this.utilService.displayToast(_this.translations.couldNotAddBadRequest);
            }
            else if (error.status === 404) {
                _this.getErrorMessage('errors.userDoesNotExist');
                _this.utilService.displayToast(_this.translations.userDoesNotExist);
            }
            else {
                _this.getErrorMessage('errors.couldNotAddFailedRequest');
                _this.utilService.displayToast(_this.translations.couldNotAddFailedRequest);
            }
        });
    };
    ApplicationDetailsComponent.prototype.removeApiAdmin = function (id, roleAssignmentId) {
        var _this = this;
        this.userService.removeUserFromRole(id, roleAssignmentId).subscribe(function (response) {
            _this.getAdmins();
        }, function (error) {
            _this.utilService.displayToast(_this.translations.couldNotRevokeAccess);
            _this.getErrorMessage('errors.couldNotRevokeAcess');
        });
    };
    ApplicationDetailsComponent.prototype.removeCustomerAdmin = function (id, roleAssignmentId) {
        var _this = this;
        this.userService.removeUserFromRole(id, roleAssignmentId).subscribe(function (response) {
            _this.getAdmins();
        }, function (error) {
            _this.utilService.displayToast(_this.translations.couldNotRevokeAccess);
            _this.getErrorMessage('errors.couldNotRevokeAcess');
        });
    };
    ApplicationDetailsComponent.prototype.onSelectDuration = function (event, duration) {
        var today = new Date();
        if (event.isUserInput) {
            switch (duration) {
                case '1 year': {
                    var oneYearFromToday = new Date();
                    oneYearFromToday.setFullYear(today.getFullYear() + 1);
                    this.passwordCredentialForm.get('endDate').setValue(oneYearFromToday);
                    break;
                }
                case '2 years': {
                    var twoYearsFromToday = new Date();
                    twoYearsFromToday.setFullYear(today.getFullYear() + 2);
                    this.passwordCredentialForm.get('endDate').setValue(twoYearsFromToday);
                    break;
                }
            }
        }
    };
    ApplicationDetailsComponent.prototype.addPasswordCredential = function () {
        var _this = this;
        this.appService.addNewClientSecret(this.application.applicationId, this.passwordCredentialForm.value).subscribe(function (generatedSecret) {
            console.log(generatedSecret);
            _this.generatedSecret = generatedSecret;
            _this.initPasswordCredentialForm();
            _this.passwordCredentialForm.reset();
            _this.getApplicationByAppId();
            _this.isNewSecretCreated = true;
        }, function (error) {
            _this.utilService.displayToast(_this.translations.couldNotAddPasswordCredentials);
            _this.getErrorMessage('errors.couldNotAddPasswordCredentials');
        });
    };
    ApplicationDetailsComponent.prototype.deleteSecret = function (keyId) {
        var _this = this;
        this.appService.deleteClientSecret(this.id, keyId).subscribe(function (updatedApplication) {
            _this.application = updatedApplication;
        }, function (error) {
            _this.utilService.displayToast(_this.translations.appNotFound);
            _this.getErrorMessage('errors.couldNotDeleteSecret');
        });
    };
    ApplicationDetailsComponent.prototype.initApplicationForm = function () {
        this.applicationForm = this.fb.group({
            applicationName: [''],
            applicationId: [''],
            id: [''],
            criticality: ['Undefined']
        });
    };
    ApplicationDetailsComponent.prototype.initPasswordCredentialForm = function () {
        this.passwordCredentialForm = this.fb.group({
            displayName: [''],
            endDate: ['']
        });
    };
    ApplicationDetailsComponent.prototype.initCustomerAdminForm = function () {
        this.customerAdminForm = this.fb.group({
            email: ['', [Validators.required]]
        });
    };
    ApplicationDetailsComponent.prototype.initApiAdminForm = function () {
        this.apiAdminForm = this.fb.group({
            email: ['', [Validators.required]]
        });
    };
    ApplicationDetailsComponent.prototype.setFormValues = function (app) {
        this.applicationForm.get('applicationName').setValue(app.displayName);
        this.applicationForm.get('applicationId').setValue(app.applicationId);
        this.applicationForm.get('id').setValue(app.id);
        this.applicationForm.get('criticality').setValue(app.criticality);
    };
    ApplicationDetailsComponent.prototype.resetErrorMessage = function () {
        this.displayErrorMessage = '';
    };
    ApplicationDetailsComponent.prototype.getErrorMessage = function (message) {
        var _this = this;
        this.translateService.get(message).subscribe(function (text) {
            console.log(text);
            _this.displayErrorMessage = text;
        });
    };
    ApplicationDetailsComponent.prototype.backToApplicationList = function () {
        this.router.navigateByUrl('/applications');
    };
    ApplicationDetailsComponent.prototype.initI18nData = function () {
        var _this = this;
        this.translateService.get('messages').subscribe(function (data) {
            _this.translations = data;
        });
    };
    return ApplicationDetailsComponent;
}(BaseEntityEdit));
export { ApplicationDetailsComponent };
