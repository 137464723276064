import { BaseEntityEdit } from "src/app/base/base.entity.edit";
import { IAdministratorAppRoleAssignment } from "src/app/models/IAdministratorAppRoleAssignment";
import { IApplication } from "src/app/models/IApplication";
import { ICreateAppRoleAssignment } from "src/app/models/ICreateAppRoleAssignment";
import { ApplicationService } from "src/app/services/application.service";
import { UserService } from "src/app/services/user.service";
import { UtilsService } from "src/app/services/utils.service";

import { Component, OnInit } from "@angular/core";
import { FormBuilder, FormGroup, FormGroupDirective, Validators } from "@angular/forms";
import { MatDialog } from "@angular/material/dialog";
import { ActivatedRoute, Router } from "@angular/router";
import { TranslateService } from "@ngx-translate/core";

@Component({
    selector: 'app-application-details',
    templateUrl: './application-details.component.html',
    styleUrls: ['./application-details.component.scss']
})
export class ApplicationDetailsComponent extends BaseEntityEdit
    implements OnInit {
    application: IApplication;
    id: any;
    isLoading: boolean;
    private translations: any;
    public criticalities: string[];
    public durations: string[];
    displayErrorMessage: string;
    applicationForm: FormGroup;
    passwordCredentialForm: FormGroup;
    customerAdminForm: FormGroup;
    apiAdminForm: FormGroup;
    endDate: Date;
    isNewSecretCreated: boolean;
    generatedSecret: string;
    administrators: IAdministratorAppRoleAssignment;
    role: string;


    constructor(
        private fb: FormBuilder,
        private appService: ApplicationService,
        private route: ActivatedRoute,
        public utilService: UtilsService,
        protected router: Router,
        protected matDialog: MatDialog,
        private translateService: TranslateService,
        private userService: UserService
    ) {
        super(matDialog, router, 'applications');
        this.criticalities = ['Undefined', 'C0', 'C1', 'C2', 'C3', 'C4'];
        this.durations = ['1 year', '2 years'];
    }

    ngOnInit(): void {
        this.initI18nData();
        this.initApplicationForm();
        this.initPasswordCredentialForm();
        this.initCustomerAdminForm();
        this.initApiAdminForm();
        this.route.params.subscribe(params => {
            this.id = params.id;
            this.getApplicationByAppId();
            this.getAdmins();
            this.role = localStorage.getItem('ccmsrole');
        });
    }

    private getApplicationByAppId() {
        this.appService.getApplicationByAppId(this.id).subscribe(application => {
            this.application = application;
            this.setFormValues(this.application);
            console.log(this.application);
        }, error => {
            if (error.status === 403) {
                this.utilService.displayToast(this.translations.noPermissions);
                this.goBack();
                return;
            }
            this.utilService.displayToast(this.translations.appNotFound);
        });
    }

    private getAdmins() {
        this.userService.getAdministratorsForApplication(this.id).subscribe(admins => {
            this.administrators = admins;
            console.log(this.administrators);
        }, error => {
            this.translateService.get('messages.'+error.error).subscribe((text: string) => {
                if (text != error.error) {
                    this.utilService.displayToast(text);
                }
                else {
                    this.utilService.displayToast(this.translations.appNotFound);
                }
            });
        });
    }

    assignApiAdmin(formDirective: FormGroupDirective) {
        const roleAssignment: ICreateAppRoleAssignment =  {
            applicationId: this.id,
            userEmail: this.apiAdminForm.get('email').value,
            azureAdRoleId: this.application.appRoles.find(x => x.displayName === 'ApiAdmin').roleId
        };

        this.userService.assignUserToApplication(roleAssignment).subscribe(
            _ => {
            this.getAdmins();
            this.initApiAdminForm();
            formDirective.resetForm();
            this.apiAdminForm.reset();
        },
        error => {
            console.log(error);
            if (error.status === 400) {
                this.getErrorMessage('errors.couldNotAddBadRequest');
                this.utilService.displayToast(this.translations.couldNotAddBadRequest);
            } else if (error.status === 404) {
                this.getErrorMessage('errors.userDoesNotExist');
                this.utilService.displayToast(this.translations.userDoesNotExist);
            } else {
                this.getErrorMessage('errors.couldNotAddFailedRequest');
                this.utilService.displayToast(this.translations.couldNotAddFailedRequest);
            }
        });
    }

    assignCustomerAdmin(formDirective: FormGroupDirective) {
        const roleAssignment: ICreateAppRoleAssignment =  {
            applicationId: this.id,
            userEmail: this.customerAdminForm.get('email').value,
            azureAdRoleId: this.application.appRoles.find(x => x.displayName === 'CustomerAdmin').roleId
        };

        this.userService.assignUserToApplication(roleAssignment).subscribe(_ => {
            this.getAdmins();
            this.initCustomerAdminForm();
            formDirective.resetForm();
            this.customerAdminForm.reset();
        },
        error => {
            console.log(error);
            if (error.status === 400) {
                this.getErrorMessage('errors.couldNotAddBadRequest');
                this.utilService.displayToast(this.translations.couldNotAddBadRequest);
            } else if (error.status === 404) {
                this.getErrorMessage('errors.userDoesNotExist');
                this.utilService.displayToast(this.translations.userDoesNotExist);
            } else {
                this.getErrorMessage('errors.couldNotAddFailedRequest');
                this.utilService.displayToast(this.translations.couldNotAddFailedRequest);
            }
        });
    }

    removeApiAdmin(id: string, roleAssignmentId: string) {
        this.userService.removeUserFromRole(id, roleAssignmentId).subscribe(
            response => {
                this.getAdmins();
            },
            error => {
                this.utilService.displayToast(this.translations.couldNotRevokeAccess);
                this.getErrorMessage('errors.couldNotRevokeAcess');
            }
        );
    }

    removeCustomerAdmin(id: string, roleAssignmentId: string) {
        this.userService.removeUserFromRole(id, roleAssignmentId).subscribe(
            response => {
                this.getAdmins();
            },
            error => {
                this.utilService.displayToast(this.translations.couldNotRevokeAccess);
                this.getErrorMessage('errors.couldNotRevokeAcess');
            }
        );
    }

    onSelectDuration(event: any, duration: string) {
        const today = new Date();

        if (event.isUserInput) {
            switch (duration) {
                case '1 year': {
                    const oneYearFromToday = new Date();
                    oneYearFromToday.setFullYear(today.getFullYear() + 1);
                    this.passwordCredentialForm.get('endDate').setValue(oneYearFromToday);
                    break;
                }
                case '2 years': {
                    const twoYearsFromToday = new Date();
                    twoYearsFromToday.setFullYear(today.getFullYear() + 2);
                    this.passwordCredentialForm.get('endDate').setValue(twoYearsFromToday);
                    break;
                }
            }

        }
    }
    addPasswordCredential() {
        this.appService.addNewClientSecret(this.application.applicationId, this.passwordCredentialForm.value).subscribe(
            generatedSecret => {
                console.log(generatedSecret);
                this.generatedSecret = generatedSecret;
                this.initPasswordCredentialForm();
                this.passwordCredentialForm.reset();
                this.getApplicationByAppId();
                this.isNewSecretCreated = true;
            },
            error => {
                this.utilService.displayToast(this.translations.couldNotAddPasswordCredentials);
                this.getErrorMessage('errors.couldNotAddPasswordCredentials');
            }
        );
    }

    deleteSecret(keyId: string) {
        this.appService.deleteClientSecret(this.id, keyId).subscribe(updatedApplication => {
            this.application = updatedApplication;
        },
        error => {
            this.utilService.displayToast(this.translations.appNotFound);
            this.getErrorMessage('errors.couldNotDeleteSecret');
        });
    }

    private initApplicationForm() {
        this.applicationForm = this.fb.group({
            applicationName: [''],
            applicationId: [''],
            id: [''],
            criticality: ['Undefined']
        });
    }

    private initPasswordCredentialForm() {
        this.passwordCredentialForm = this.fb.group({
            displayName: [''],
            endDate: ['']
        });
    }

    private initCustomerAdminForm() {
        this.customerAdminForm = this.fb.group({
            email: ['', [Validators.required]]
        });
    }

    private initApiAdminForm() {
        this.apiAdminForm = this.fb.group({
            email: ['', [Validators.required]]
        });
    }

    private setFormValues(app: IApplication) {
        this.applicationForm.get('applicationName').setValue(app.displayName);
        this.applicationForm.get('applicationId').setValue(app.applicationId);
        this.applicationForm.get('id').setValue(app.id);
        this.applicationForm.get('criticality').setValue(app.criticality);
    }

    resetErrorMessage() {
        this.displayErrorMessage = '';
    }

    private getErrorMessage(message: any) {
        this.translateService.get(message).subscribe((text: string) => {
            console.log(text);
            this.displayErrorMessage = text; });
    }

    backToApplicationList() {
        this.router.navigateByUrl('/applications');
    }

    private initI18nData() {
        this.translateService.get('messages').subscribe(data => {
            this.translations = data;
        });
    }

    // generateSecretKey() {
    //     const pwdChars = '0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz';
    //     const pwdLen = 40;
    //     // tslint:disable-next-line: only-arrow-functions
    //     const generatedSecret = Array(pwdLen).fill(pwdChars).map(function(x) { return x[Math.floor(Math.random() * x.length)]; }).join('');

    //     const pwField = document.getElementById('passwordInput') as HTMLInputElement;
    //     if (pwField.type === 'password') {
    //         pwField.type = 'text';
    //     }
    //     this.passwordCredentialForm.get('value').setValue(generatedSecret);
    // }

    // generateGuid() {
    //     const generatedGuid = Guid.generateGuid();

    //     this.passwordCredentialForm.get('keyId').setValue(generatedGuid);
    // }

}
