import { MsalService } from "src/app/shared/authentication/msal.service";

import { Component, OnInit } from "@angular/core";

@Component({
    selector: 'app-forbidden',
    templateUrl: './forbidden.component.html',
    styleUrls: ['./forbidden.component.scss']
})
export class ForbiddenComponent implements OnInit {
    constructor(private msalService: MsalService) {}

    ngOnInit() {}

    logout(): void {
        this.msalService.logout();
    }
}
