import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { IApplication } from './../models/IApplication';
import { BaseHttpProvider } from 'src/app/base/base.http.provider';
import { Injectable } from '@angular/core';
import { ICreateApplication } from '../models/ICreateApplication';
import { ICreatePasswordCredential } from '../models/ICreatePasswordCredential';

@Injectable({
    providedIn: 'root'
})
export class ApplicationService extends BaseHttpProvider<IApplication>{

    constructor(
        http: HttpClient
    ) {
        super(http, '/api/applications');
    }


    public getApplications(query: string): Observable<Array<IApplication>> {
        if (query) {
            const searchQueryEncoded = query ? encodeURIComponent(query) : '';
            return this.http.get<Array<IApplication>>(this.url + '?query=' + searchQueryEncoded);
        } else {
            return this.http.get<Array<IApplication>>(this.url);
        }
    }

    public getApplicationByAppId(appId: string): Observable<IApplication> {
        return this.http.get<IApplication>(this.url + `/${appId}`);
    }

    public createApplication(data: ICreateApplication): Observable<IApplication> {
        return this.http.post<IApplication>(this.url, data);
    }

    public addNewClientSecret(applicationId: string, body: ICreatePasswordCredential): Observable<any> {
        return this.http.patch<IApplication>(`${this.url}/${applicationId}`, body);
    }

    public deleteClientSecret(applicationId: string, keyId: string): Observable<IApplication> {
        return this.http.delete<IApplication>(`${this.url}/${applicationId}/clientCredentials/${keyId}`);
    }

}
