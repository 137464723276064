import { MsalService } from './msal.service';
import { Injectable } from '@angular/core';
import { CanActivate } from '@angular/router';

@Injectable()
export class AuthGuard implements CanActivate {
  constructor(private msalService: MsalService) {}
  canActivate() {
    return this.msalService.isOnline();
  }
}
