import * as tslib_1 from "tslib";
import { BaseEntityCreate } from "src/app/base/base.entity.create";
import { ValidateWhiteSpace } from "src/app/common/validators/whitespace.validator";
import { ApplicationService } from "src/app/services/application.service";
import { UtilsService } from "src/app/services/utils.service";
import { OnDestroy, OnInit } from "@angular/core";
import { FormBuilder, Validators } from "@angular/forms";
import { MatDialog } from "@angular/material/dialog";
import { Router } from "@angular/router";
import { TranslateService } from "@ngx-translate/core";
var ApplicationCreationComponent = /** @class */ (function (_super) {
    tslib_1.__extends(ApplicationCreationComponent, _super);
    function ApplicationCreationComponent(appService, router, dialog, formBuilder, translateService, utilService) {
        var _this = _super.call(this, dialog, router, 'applications') || this;
        _this.appService = appService;
        _this.router = router;
        _this.dialog = dialog;
        _this.formBuilder = formBuilder;
        _this.translateService = translateService;
        _this.utilService = utilService;
        _this.isLoading = false;
        // error message displayed above forms
        _this.displayErrorMessage = '';
        _this.productOptions = new Array('ProductSuite', 'APIProduct', 'API');
        _this.environments = new Array('DEV', 'INT', 'PRD');
        _this.customer = '';
        _this.customerDescription = '';
        _this.applicationApiName = '';
        _this.initI18nData();
        return _this;
    }
    ApplicationCreationComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.appForm = this.formBuilder.group({
            displayName: [
                '',
                [
                    Validators.required,
                    Validators.maxLength(90),
                    ValidateWhiteSpace
                ]
            ]
        });
        // check if form value has changed
        this.appForm.valueChanges.subscribe(function (val) {
            if (_this.appForm.dirty) {
                _this.utilService.isDirty = true;
            }
        });
    };
    ApplicationCreationComponent.prototype.onSubmit = function () {
        var _this = this;
        if (this.appForm.invalid) {
            this.getErrorMessage('errors.invalidApplication');
            return;
        }
        if (!this.appForm.get('displayName').value.startsWith('CCMS-')) {
            this.getErrorMessage('errors.invalidDisplayNameForApplication');
            return;
        }
        this.appToCreate = {
            displayName: this.appForm.get('displayName').value
        };
        this.isLoading = true;
        this.appCreation = this.appService
            .createApplication(this.appToCreate)
            .subscribe(function (response) {
            _this.isLoading = false;
            _this.utilService.isDirty = false;
            _this.utilService.displayToast(_this.translations['createdApplication']);
            _this.goBack();
        }, function (err) {
            _this.isLoading = false;
            _this.getErrorMessage('errors.couldNotCreateApplication');
        });
    };
    // get translation for tips messages
    ApplicationCreationComponent.prototype.getErrorMessage = function (message) {
        var _this = this;
        this.translateService.get(message).subscribe(function (text) {
            _this.displayErrorMessage = text;
        });
    };
    ApplicationCreationComponent.prototype.initI18nData = function () {
        var _this = this;
        this.translateService.get('errors').subscribe(function (data) {
            _this.translations = data;
        });
    };
    ApplicationCreationComponent.prototype.resetErrorMessage = function () {
        this.displayErrorMessage = '';
    };
    /** ON CHANGE DETECTION METHODS */
    ApplicationCreationComponent.prototype.onChangeProductType = function (event, selectedProductType) {
        if (event.isUserInput) {
            this.option = selectedProductType;
            this.setDisplayName();
        }
    };
    ApplicationCreationComponent.prototype.onChangeEnvironment = function (event, selectedEnvironment) {
        if (event.isUserInput) {
            this.environment = selectedEnvironment;
            this.setDisplayName();
        }
    };
    ApplicationCreationComponent.prototype.onChangeApplicationApiName = function (event, value) {
        this.applicationApiName = value;
        this.setDisplayName();
    };
    ApplicationCreationComponent.prototype.onChangeCustomer = function (event, value) {
        this.customer = value;
        this.setDisplayName();
    };
    ApplicationCreationComponent.prototype.onChangeDescription = function (event, value) {
        this.customerDescription = value;
        this.setDisplayName();
    };
    ApplicationCreationComponent.prototype.setDisplayName = function () {
        if (this.customerDescription && this.customerDescription !== '') {
            this.appForm
                .get('displayName')
                .setValue("CCMS-" + this.applicationApiName + "-" + this.customer + "-" + this.option + "-" + this.customerDescription + "-" + this.environment);
        }
        else {
            this.appForm
                .get('displayName')
                .setValue("CCMS-" + this.applicationApiName + "-" + this.customer + "-" + this.option + "-" + this.environment);
        }
    };
    ApplicationCreationComponent.prototype.ngOnDestroy = function () {
        if (this.appCreation) {
            this.appCreation.unsubscribe();
        }
    };
    return ApplicationCreationComponent;
}(BaseEntityCreate));
export { ApplicationCreationComponent };
