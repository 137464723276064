import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";

import { AppComponent } from "./app.component";
import {
    ApplicationCreationComponent
} from "./routes/applications/application-creation/application-creation.component";
import {
    ApplicationDetailsComponent
} from "./routes/applications/application-details/application-details.component";
import { ApplicationsComponent } from "./routes/applications/applications.component";
import { ForbiddenComponent } from "./routes/forbidden/forbidden.component";
import { InvitationsComponent } from "./routes/invitations/invitations.component";

const routes: Routes = [
    {
        pathMatch: 'full',
        path: '',
        component: ApplicationsComponent
    },
    {
        pathMatch: 'full',
        path: 'invitations',
        component: InvitationsComponent
    },
    {
        pathMatch: 'full',
        path: 'applications',
        component: ApplicationsComponent
    },
    {
        pathMatch: 'full',
        path: 'applications/createApp',
        component: ApplicationCreationComponent
    },
    {
        path: 'applications/:id',
        component: ApplicationDetailsComponent
    },
    {path: 'forbidden', component: ForbiddenComponent},
    { pathMatch: 'full', path: '**', component: AppComponent }
];

@NgModule({
    imports: [RouterModule.forRoot(routes)],
    exports: [RouterModule]
})
export class AppRoutingModule {}
