import { Component, OnInit } from "@angular/core";
import { NavigationEnd, Router } from "@angular/router";
import { TranslateService } from "@ngx-translate/core";

import { JwtHelper } from "./common/helpers/jwt.helper";
import { AppInsightService } from "./shared/appinsights/appinsights.service";
import { MsalService } from "./shared/authentication/msal.service";

export interface Menu {
    name: string;
    link: string;
}

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
    title = 'ClientApp';
    username: string;
    initials: string;
    // tslint:disable-next-line: no-inferrable-types
    isLoggedIn: boolean = false;
    languageOptions = new Array<string>('en', 'de');
    private translations: any;
    role: any;
    jwtHelper = new JwtHelper();

    data: Menu[];
    // initial menu item selected
    private currentRouteLink: string = '';

    constructor(
        protected router: Router,
        private translate: TranslateService,
        private msalService: MsalService,
        private appInsightService: AppInsightService
    ) {
        this.translate.use('en');
        // translate.setDefaultLang(translate.getBrowserLang());

        this.initI18nData();
        this.initials = this.splitName(this.username);
    }

    ngOnInit() {
        // check of login token si stored in session storage
        // for more info check MsalService
        if (localStorage.getItem('msal.idtoken') != null) {
            this.isLoggedIn = true;
        }

        // double check if msal authentication was done
        this.isLoggedIn = this.msalService.isOnline();

        // Get user role and onsly display users tab for global admins
        if (this.isLoggedIn) {
            // Gettting name and name initials
            this.username = this.msalService.getUser().name;
            this.initials = this.splitName(this.username);
            this.initRole();
            console.log(this.role);
            if (!this.role) {
                this.router.navigateByUrl('/forbidden');
            }
        }

        // detect navigation changes to setup the correct selected menu item
        this.router.events.subscribe(event => {
            if (event instanceof NavigationEnd) {
                const route = event['url'].slice(1).split('/');
                this.currentRouteLink = this.getCurrentRoute(route[0]);
            }
        });
    }

    private initRole() {
        this.role = this.jwtHelper.decodeToken(
            localStorage.getItem('msal.idtoken')
        )['http://schemas.microsoft.com/ws/2008/06/identity/claims/role'];
        localStorage.setItem('ccmsrole', this.role);
        switch (this.role) {
            case 'APIMManager':
                this.data = [
                    { name: 'Invitations', link: '/invitations' },
                    { name: 'Applications', link: '/applications' }
                ];
                break;
            case 'APIAdmin':
                this.data = [
                    { name: 'Invitations', link: '/invitations' },
                    { name: 'Applications', link: '/applications' }
                ];
                break;
            case 'CustomerAdmin':
                this.data = [{ name: 'Applications', link: '/applications' }];
                break;
        }
    }

    private initI18nData() {
        this.translate.get('errors').subscribe(data => {
            this.translations = data;
        });
    }

    changeLanguage() {
        this.translate.use('en');
        console.log(this.translate.getBrowserLang());
    }

    onChangeLanguage(event: any, languageOption: string) {
        // onSelectionChange triggers twice for selecting and deselecting the values
        // therefore, we check for user input to be true, so only the clicked on change gets handled
        if (event.isUserInput) {
            if (languageOption === 'de') {
                this.translate.use('de');
            } else if (languageOption === 'en') {
                this.translate.use('en');
            } else {
                // default
                this.translate.use('en');
            }
        }
    }

    /**
     * @private
     * @desc get the first 2 initials of the user
     * @param {string} fullName
     * @returns
     * @memberof AppComponent
     */
    private splitName(fullName: string) {
        return fullName
            ? fullName
                  .split(' ')
                  .map(value => value[0])
                  .join('')
                  .substring(0, 2)
                  .toLocaleUpperCase()
            : '';
    }

    private getCurrentRoute(url: string): string {
        let route: string = '';
        switch (url) {
            case 'invitations':
                route = '/invitations';
                break;
            case 'applications':
                route = '/applications';
                break;
            case '':
                route = '/applications';
        }
        return route;
    }

    /**
     * @desc check the selected route
     * @param {string} link
     * @returns {boolean}
     * @memberof AppComponent
     */
    isSelected(link: string): boolean {
        return link === this.currentRouteLink;
    }

    /**
     * @desc validates the routes on back
     *          navigates to the desired route
     * @param {string} link
     * @returns
     * @memberof AppComponent
     */
    goToRoute(link: string) {
        this.router.navigateByUrl(link);
    }

    /**
     * @desc redirect to the AZURE login
     * @memberof AppComponent
     */
    login(): void {
        this.msalService.login();
        this.ngOnInit();
    }

    /**
     * @desc logout from AD + remove token from sessionStorage
     * @memberof AppComponent
     */
    logout(): void {
        this.msalService.logout();
        sessionStorage.removeItem('token');
    }
}
