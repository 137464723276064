<div class="myWrapper">
  <div class="titleWrapper">
    <a (click)="goBack(appForm)">
      <i class="material-icons">
        arrow_back
      </i>
    </a>
    <div class="title">{{ 'messages.addNewApplication' | translate }} </div>
  </div>
  <div *ngIf="isLoading" class="spinnerWrapper">
    <mat-spinner></mat-spinner>
  </div>
  <div class="bottomWrapper">
    <div class="mainWrapper">
      <div class="leftWrapper">
        <div *ngIf="displayErrorMessage.length" class="errorMessage">
          <div>{{displayErrorMessage}}</div>
          <i id="lowerCloseIcon" class="material-icons" matSuffix (click)="resetErrorMessage()">close</i>
        </div>


        <form class="example-form" [formGroup]="appForm" (ngSubmit)="onSubmit()">
          <mat-form-field class="basicInput">
            <input formControlName="displayName" required matInput
              placeholder="{{ 'messages.applicationName' | translate }} ">
            <mat-error
              *ngIf="appForm.get('displayName').hasError('required') || appForm.get('displayName').hasError('invalidInput')">{{
                    'errors.requiredApplicationName' | translate }}</mat-error>
            <mat-error *ngIf="appForm.get('displayName').hasError('maxlength')">{{ 'errors.applicationNameMaxLength' |
                    translate }}</mat-error>
          </mat-form-field>
        </form>

        <div class="submitWrapper">
          <button (click)="onSubmit()" [ngClass]="{'disabledButton': appForm.invalid}" [disabled]="appForm.invalid"
            class="coloredButton">{{ 'messages.addApplication' | translate }} </button>
        </div>
      </div>
      <div class="rightWrapper">
        <h4>{{'messages.applicationProperties' | translate}}</h4>
        <mat-form-field class="rightInput">
          <input required matInput placeholder="{{ 'messages.applicationApiName' | translate }}" [(ngModel)]="applicationApiName" (change)="onChangeApplicationApiName($event, applicationApiName)">
        </mat-form-field>
        <mat-form-field class="rightInput">
          <input required matInput placeholder="{{ 'messages.customerName' | translate }}" [(ngModel)]="customer" (change)="onChangeCustomer($event, customer)">
        </mat-form-field>
        <mat-form-field class="rightInput">
          <mat-select class="selector"
            placeholder="{{ 'messages.productType' | translate }}">
            <mat-option *ngFor="let option of productOptions" (onSelectionChange)="onChangeProductType($event, option)"
              [value]="option">
              {{option | translate}}
            </mat-option>
          </mat-select>
        </mat-form-field>
        <mat-form-field class="rightInput">
          <input matInput placeholder="{{ 'messages.shortDescription' | translate }}" [(ngModel)]="customerDescription" (change)="onChangeDescription($event, customerDescription)">
        </mat-form-field>
        <mat-form-field class="rightInput">
          <mat-select class="selector"
            placeholder="{{ 'messages.environment' | translate }}">
            <mat-option *ngFor="let environment of environments"
              (onSelectionChange)="onChangeEnvironment($event, environment)" [value]="environment">
              {{environment | translate}}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>
    </div>
  </div>
</div>