import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { BaseEntityCreate } from 'src/app/base/base.entity.create';

export abstract class BaseEntityEdit extends BaseEntityCreate {
    constructor(
        protected dialog: MatDialog,
        protected router: Router,
        backRoute: string
    ) {
        super(dialog, router, backRoute);
    }
}
