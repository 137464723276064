<div class="frameWrapper">
    <div class="searchWrapper">
        <mat-form-field appearance="standard">
            <input matInput (keyup)="filter($event.target.value)" placeholder="{{'Search' | translate}}">
            <i id="lowerSearchIcon" class="searchIcon" matSuffix></i>
        </mat-form-field>

        <!-- <div *ngIf="isLoaded || dataSource"> -->
            <a *ngIf="role == 'APIMManager' || role == 'APIAdmin'" routerLink="/applications/createApp"
                routerLinkActive="active" class="coloredButton">
                {{ 'messages.createApplication' | translate }} </a>
        <!-- </div> -->

    </div>
    <div *ngIf="showLoading()" class="spinnerWrapper">
        <mat-spinner></mat-spinner>
    </div>
    <div class="tableWrapper">
        <table mat-table [dataSource]="dataSource" matSort class="mat-elevation-z8">
            <ng-container matColumnDef="displayName">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> {{ 'messages.name' | translate }} </th>
                <td mat-cell *matCellDef="let element"> {{element.displayName}} </td>
            </ng-container>
            <ng-container matColumnDef="applicationId">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> {{ 'messages.appId' | translate }} </th>
                <td mat-cell *matCellDef="let element"> {{element.applicationId}} </td>
            </ng-container>
            <ng-container matColumnDef="edit">
                <th mat-header-cell *matHeaderCellDef> {{ 'messages.edit' | translate }} </th>
                <td mat-cell *matCellDef="let element" (click)="edit(element.applicationId)">
                    <a>
                        <i class="ediIcon"></i>
                    </a>
                </td>
            </ng-container>
            <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
        </table>
        <div class="noResults" *ngIf="isListEmpty()">
            {{ 'messages.noDataRecieved' | translate }} </div>
        <mat-paginator [pageSizeOptions]="[10, 20, 30]" showFirstLastButtons></mat-paginator>
    </div>
</div>