<div id="container-header" class="container">
  <div id="headerComponent" class="header">
      <div id="logoWrapper" class="logoWrapper">
          <a id="logoLink" style="color:white;" class="logo">
            <b>{{ 'Title' | translate}}</b>

              <!-- <img id="logoImg" src="../../assets/logo.png" alt="logo" /> -->
          </a>

      </div>
      <!-- <button mat-raised-button color="primary" (click)="changeLanguage()">{{'Language.settings' | translate}}</button> -->
      <!-- <mat-select id="white-select" class="selector" style="width:150px; margin-top:8px;" placeholder="{{ 'Language.settings' | translate }}" >
        <mat-option  *ngFor="let langOpt of languageOptions" (onSelectionChange)="onChangeLanguage($event, langOpt)" [value]="langOpt">
            {{langOpt | translate}}
        </mat-option>
    </mat-select> -->
    <div id="headerButtonsWrapper" class="buttons">
        <button *ngIf="isLoggedIn" (click)="isLoggedIn ? logout() : login()" mat-button id="headerLogoutButton"> {{ (this.isLoggedIn ? 'messages.logout' : 'messages.login') | translate }}</button>
    </div>
  </div>
  <div class="scrollableWrapper">
      <div id="secondHeader" class="secondHeader">
          <div id="photoWrapper" class="photoWrapper">
              <div class="letterWrapper">
                  {{initials}}</div>
          </div>
          <div id="headerTextWrapper" class="headerTextWrapper">
              <h2 class="flexItems greeting">{{ 'Greetings' | translate }}
                  <span>{{username}}</span>!</h2>
                  <br />
                  <div>
                      You are logged in as: <span style="color: #ee8101; font-weight: bold;">{{role}}</span>
                  </div>
          </div>

      </div>
      <div class="bottomWrapper">
          <div class="leftMenu">
              <mat-selection-list #menu>
                  <div *ngFor="let item of data;let i = index;">
                      <mat-list-option [selected]="isSelected(item.link)" (click)="goToRoute(item.link)">
                          <a mat-list-item >
                              <i matListIcon class="{{item.name}}"></i> {{item.name | translate}}</a>
                      </mat-list-option>
                  </div>
              </mat-selection-list>
              <span class="version">{{ 'Version' | translate }}</span>
          </div>
          <div class="actualContent">
              <router-outlet></router-outlet>
          </div>
      </div>
  </div>
  <div *ngIf="!isLoggedIn" class="loginWrapper">
    <div class="welcomeMessage">
       <div>
        {{ 'messages.welcomeLogin' | translate }}
       </div>
        <a class="coloredButton" (click)="login()">{{ 'messages.login' | translate }} </a>
    </div>
</div>
</div>
