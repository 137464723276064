import * as tslib_1 from "tslib";
import { HttpClient } from '@angular/common/http';
import { BaseHttpProvider } from 'src/app/base/base.http.provider';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
var ApplicationService = /** @class */ (function (_super) {
    tslib_1.__extends(ApplicationService, _super);
    function ApplicationService(http) {
        return _super.call(this, http, '/api/applications') || this;
    }
    ApplicationService.prototype.getApplications = function (query) {
        if (query) {
            var searchQueryEncoded = query ? encodeURIComponent(query) : '';
            return this.http.get(this.url + '?query=' + searchQueryEncoded);
        }
        else {
            return this.http.get(this.url);
        }
    };
    ApplicationService.prototype.getApplicationByAppId = function (appId) {
        return this.http.get(this.url + ("/" + appId));
    };
    ApplicationService.prototype.createApplication = function (data) {
        return this.http.post(this.url, data);
    };
    ApplicationService.prototype.addNewClientSecret = function (applicationId, body) {
        return this.http.patch(this.url + "/" + applicationId, body);
    };
    ApplicationService.prototype.deleteClientSecret = function (applicationId, keyId) {
        return this.http.delete(this.url + "/" + applicationId + "/clientCredentials/" + keyId);
    };
    ApplicationService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function ApplicationService_Factory() { return new ApplicationService(i0.ɵɵinject(i1.HttpClient)); }, token: ApplicationService, providedIn: "root" });
    return ApplicationService;
}(BaseHttpProvider));
export { ApplicationService };
