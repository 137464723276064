import { HttpClient } from '@angular/common/http';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
var InvitationService = /** @class */ (function () {
    function InvitationService(http) {
        this.http = http;
        this.baseUrl = 'api/invitations';
    }
    InvitationService.prototype.inviteUser = function (data) {
        return this.http.post(this.baseUrl, data);
    };
    InvitationService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function InvitationService_Factory() { return new InvitationService(i0.ɵɵinject(i1.HttpClient)); }, token: InvitationService, providedIn: "root" });
    return InvitationService;
}());
export { InvitationService };
