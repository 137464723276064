/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./forbidden.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@ngx-translate/core";
import * as i3 from "../../../../node_modules/@angular/material/button/typings/index.ngfactory";
import * as i4 from "@angular/material/button";
import * as i5 from "@angular/cdk/a11y";
import * as i6 from "@angular/platform-browser/animations";
import * as i7 from "./forbidden.component";
import * as i8 from "../../shared/authentication/msal.service";
var styles_ForbiddenComponent = [i0.styles];
var RenderType_ForbiddenComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_ForbiddenComponent, data: {} });
export { RenderType_ForbiddenComponent as RenderType_ForbiddenComponent };
export function View_ForbiddenComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 21, "div", [["class", "container"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 20, "div", [["class", "error-template"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 1, "h2", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["403 Forbidden Page"])), (_l()(), i1.ɵeld(4, 0, null, null, 2, "div", [["class", "error-details"]], null, null, null, null, null)), (_l()(), i1.ɵted(5, null, [" ", " "])), i1.ɵpid(131072, i2.TranslatePipe, [i2.TranslateService, i1.ChangeDetectorRef]), (_l()(), i1.ɵeld(7, 0, null, null, 9, "div", [["class", "error-advise"]], null, null, null, null, null)), (_l()(), i1.ɵeld(8, 0, null, null, 5, "div", [], null, null, null, null, null)), (_l()(), i1.ɵted(9, null, [" ", " "])), i1.ɵpid(131072, i2.TranslatePipe, [i2.TranslateService, i1.ChangeDetectorRef]), (_l()(), i1.ɵeld(11, 0, null, null, 1, "a", [["href", "mailto:p0060003969@munichre.com"], ["style", "color:#165787;"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["p0060003969@munichre.com"])), (_l()(), i1.ɵted(-1, null, [". "])), (_l()(), i1.ɵeld(14, 0, null, null, 2, "div", [], null, null, null, null, null)), (_l()(), i1.ɵted(15, null, [" ", " "])), i1.ɵpid(131072, i2.TranslatePipe, [i2.TranslateService, i1.ChangeDetectorRef]), (_l()(), i1.ɵeld(17, 0, null, null, 4, "div", [["class", "error-actions"]], null, null, null, null, null)), (_l()(), i1.ɵeld(18, 0, null, null, 3, "button", [["color", "primary"], ["mat-raised-button", ""]], [[1, "disabled", 0], [2, "_mat-animation-noopable", null]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.logout() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i3.View_MatButton_0, i3.RenderType_MatButton)), i1.ɵdid(19, 180224, null, 0, i4.MatButton, [i1.ElementRef, i5.FocusMonitor, [2, i6.ANIMATION_MODULE_TYPE]], { color: [0, "color"] }, null), (_l()(), i1.ɵted(20, 0, [" ", " "])), i1.ɵpid(131072, i2.TranslatePipe, [i2.TranslateService, i1.ChangeDetectorRef])], function (_ck, _v) { var currVal_5 = "primary"; _ck(_v, 19, 0, currVal_5); }, function (_ck, _v) { var currVal_0 = i1.ɵunv(_v, 5, 0, i1.ɵnov(_v, 6).transform("errors.forbiddenPage")); _ck(_v, 5, 0, currVal_0); var currVal_1 = i1.ɵunv(_v, 9, 0, i1.ɵnov(_v, 10).transform("errors.requestAdministratorAdvise")); _ck(_v, 9, 0, currVal_1); var currVal_2 = i1.ɵunv(_v, 15, 0, i1.ɵnov(_v, 16).transform("errors.changeAccountsAdvise")); _ck(_v, 15, 0, currVal_2); var currVal_3 = (i1.ɵnov(_v, 19).disabled || null); var currVal_4 = (i1.ɵnov(_v, 19)._animationMode === "NoopAnimations"); _ck(_v, 18, 0, currVal_3, currVal_4); var currVal_6 = i1.ɵunv(_v, 20, 0, i1.ɵnov(_v, 21).transform("errors.changeAccounts")); _ck(_v, 20, 0, currVal_6); }); }
export function View_ForbiddenComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-forbidden", [], null, null, null, View_ForbiddenComponent_0, RenderType_ForbiddenComponent)), i1.ɵdid(1, 114688, null, 0, i7.ForbiddenComponent, [i8.MsalService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var ForbiddenComponentNgFactory = i1.ɵccf("app-forbidden", i7.ForbiddenComponent, View_ForbiddenComponent_Host_0, {}, {}, []);
export { ForbiddenComponentNgFactory as ForbiddenComponentNgFactory };
