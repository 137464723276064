<div class="myWrapper">
    <div class="titleWrapper">
        <a (click)="backToApplicationList()">
            <i class="material-icons">
                arrow_back
            </i>
        </a>
        <div class="title"> {{ application?.displayName }} </div>
    </div>
    <div *ngIf="isLoading" class="spinnerWrapper">
        <mat-spinner></mat-spinner>
    </div>
    <div class="bottomWrapper">
        <div class="mainWrapper">
            <div class="leftWrapper">
                <form class="example-form" [formGroup]="applicationForm" autocomplete="off">
                    <mat-form-field class="basicInput">
                        <input matInput formControlName="applicationName"
                            placeholder="{{ 'messages.displayName' | translate }} " readonly>
                    </mat-form-field>
                    <mat-form-field class="basicInput">
                        <input matInput formControlName="applicationId"
                            placeholder="{{ 'messages.applicationId' | translate }}" readonly>
                    </mat-form-field>
                    <mat-form-field class="basicInput">
                        <input matInput formControlName="id" placeholder="{{ 'messages.objectId' | translate }}"
                            readonly>
                    </mat-form-field>
                </form>
                <div class="submitWrapper">
                </div>
            </div>
            <div class="rightWrapper">


                <mat-tab-group (selectedTabChange)="resetErrorMessage()">

                    <!-- CUSTOMER TAB -->
                    <mat-tab label="{{ 'Customers' | translate }}">
                        <h4>{{ 'messages.addCustomerAdmin' | translate }}</h4>
                        <form class="assignForm" #formDirective="ngForm" (ngSubmit)="assignCustomerAdmin(formDirective)"
                            [formGroup]="customerAdminForm" autocomplete="off">
                            <mat-form-field class="basicInput">
                                <input matInput formControlName="email"
                                    placeholder="{{ 'messages.email' | translate }} ">
                                <mat-error
                                    *ngIf="customerAdminForm.get('email').hasError('invalidEmail') || customerAdminForm.get('email').hasError('required')">
                                    Invalid email address
                                </mat-error>
                            </mat-form-field>

                            <div class="assignButtonWrapper">
                                <button type="submit" class="assignButton">{{ 'messages.assign' | translate }}</button>
                            </div>
                        </form>
                        <div class="assignedAdminWrapper">
                            <h4>{{ 'messages.alreadyAssignedCustomerAdmins' | translate }}</h4>
                            <div class="adminList" *ngIf="administrators">
                                <div class="noData" *ngIf="administrators.customerAdmins.length == 0">
                                    {{ 'errors.noCustomerAdminFound' | translate}}
                                </div>
                                <div *ngFor="let admin of administrators.customerAdmins">
                                    <div class="adminWrapper">
                                        <div class="infoWrapper">
                                            <b>{{admin.principalDisplayName}}</b><br>
                                            CustomerAdmin
                                        </div>
                                        <div *ngIf="role == 'APIMManager' || role == 'APIAdmin'" class="buttonWrapper">
                                            <button mat-button
                                                (click)="removeCustomerAdmin(admin.principalId, admin.id)">{{ 'messages.revokeAccess' | translate }}</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </mat-tab>

                    <!-- API ADMINISTRATOR TAB -->
                    <mat-tab label="{{ 'messages.administrators' | translate }}">
                        <div *ngIf="role == 'APIMManager' || role == 'APIAdmin'">
                            <h4>{{ 'messages.addApiAdmin' | translate }}</h4>
                            <form class="assignForm" #formDirective="ngForm" (ngSubmit)="assignApiAdmin(formDirective)"
                                [formGroup]="apiAdminForm" autocomplete="off">
                                <mat-form-field class="basicInput">
                                    <input matInput formControlName="email"
                                        placeholder="{{ 'messages.email' | translate }} ">
                                    <mat-error
                                        *ngIf="apiAdminForm.get('email').hasError('invalidEmail') || apiAdminForm.get('email').hasError('required')">
                                        Invalid email address
                                    </mat-error>
                                </mat-form-field>

                                <div class="assignButtonWrapper">
                                    <button type="submit"
                                        class="assignButton">{{ 'messages.assign' | translate }}</button>
                                </div>
                            </form>
                        </div>
                        <div class="assignedAdminWrapper">
                            <h4>{{ 'messages.alreadyAssignedApiAdmins' | translate }}</h4>
                            <div class="adminList" *ngIf="administrators">
                                <div class="noData" *ngIf="administrators.apiAdmins.length == 0">
                                    {{ 'errors.noApiAdminFound' | translate}}
                                </div>
                                <div *ngFor="let admin of administrators.apiAdmins">
                                    <div class="adminWrapper">
                                        <div class="infoWrapper">
                                            <b>{{admin.principalDisplayName}}</b><br>
                                            APIAdmin
                                        </div>
                                        <div *ngIf="role == 'APIMManager' || role == 'APIAdmin'" class="buttonWrapper">
                                            <button mat-button
                                                (click)="removeApiAdmin(admin.principalId, admin.id)">{{ 'messages.revokeAccess' | translate }}</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>



                    </mat-tab>

                    <!-- PASSWORD CREDENTIALS TAB -->
                    <mat-tab label="{{ 'messages.passwordCredentials' | translate }}">
                        <h4>{{ 'messages.createNewSecret' | translate}}</h4>
                        <form class="example-form" [formGroup]="passwordCredentialForm" autocomplete="off">
                            <mat-form-field class="basicInput" style="width:200px;">
                                <input matInput formControlName="displayName"
                                    placeholder="{{ 'messages.keyName' | translate }} ">
                            </mat-form-field>
                            <!-- <mat-form-field class="basicInput">
                                <input matInput formControlName="value" type="password" id="passwordInput"
                                    placeholder="{{ 'messages.secretValue' | translate }}">
                                <mat-error *ngIf="passwordCredentialForm.get('value').hasError('minlength')">
                                    {{ 'errors.secretMinLength' |translate }}</mat-error>
                            </mat-form-field>
                            <mat-form-field class="basicInput">
                                <input matInput formControlName="keyId"
                                    placeholder="{{ 'messages.keyId' | translate }}">
                            </mat-form-field> -->
                            <mat-form-field style="width:200px;">
                                <mat-select placeholder="{{ 'messages.duration' | translate }}">
                                    <mat-option *ngFor="let duration of durations"
                                        (onSelectionChange)="onSelectDuration($event, duration)" [value]="duration">
                                        {{duration | translate}}
                                    </mat-option>
                                </mat-select>
                            </mat-form-field>
                            <br />
                            <br />
                            <br />
                            <!-- <mat-form-field class="basicInput">
                                <input matInput formControlName="endDate" [matDatepicker]="endDate"
                                    placeholder="{{ 'messages.endDate' | translate }}">
                                <mat-datepicker-toggle matSuffix [for]="endDate"></mat-datepicker-toggle>
                                <mat-datepicker #endDate></mat-datepicker>
                            </mat-form-field> -->
                            <!-- <button (click)="generateSecretKey()"
                            class="assignButton">{{ 'messages.generateSecret' | translate }}</button>

                            <button (click)="generateGuid()"
                            class="assignButton">{{ 'messages.generateGuid' | translate }}</button> -->


                            <div class="assignButtonWrapper">
                                <button (click)="addPasswordCredential()"
                                    class="assignButton">{{ 'messages.addNewSecret' | translate }}</button>
                            </div>
                            <span *ngIf="isNewSecretCreated">{{ 'messages.generatedSecret' | translate}} <div
                                    style="color:green;">{{generatedSecret}}</div></span>
                        </form>
                        <h4>{{ 'messages.existingSecrets' | translate}}</h4>
                        <div class="existingSecretWrapper">
                            <div class="existingSecretList">
                                <div
                                    *ngIf="!application?.passwordCredentials || application?.passwordCredentials.length == 0">
                                    <span style="color:red;">{{ 'messages.noExistingSecrets' | translate}}</span>
                                </div>
                                <div *ngFor="let credential of application?.passwordCredentials">
                                    <div class="secretWrapper">
                                        <div class="infoWrapper">
                                            <b>{{credential.displayName}}</b><br>
                                            {{ 'messages.validUntil' | translate }}:
                                            {{credential.endDate | date:'medium'}}
                                        </div>
                                        <div class="buttonWrapper">
                                            <button mat-button
                                                (click)="deleteSecret(credential.keyId)">{{ 'messages.delete' | translate }}</button>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </mat-tab>
                </mat-tab-group>
            </div>
        </div>
    </div>
</div>