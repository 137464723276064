import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { IEntity } from './../models/IEntity';
import { environment } from 'src/environments/environment';

export abstract class BaseHttpProvider<T extends IEntity> {

    protected url: string;

    constructor(
        protected http: HttpClient,
        path: string
    ) {
        this.url = environment.urlPrefix + path;
    }

    /**
     * @desc common function for getting an entity by id
     * @param {string} id
     * @returns {Observable<T>}
     * @memberof BaseHttpProvider
     */
    public getEntity(id: string): Observable<T> {
        return this.http.get<T>(this.url + '/' + id);
    }

    /**
     * @desc common function for creating an entity
     * @param {T} entity
     * @returns {Observable<T>}
     * @memberof BaseHttpProvider
     */
    public createEntity(entity: T): Observable<T> {
        return this.http.post<T>(this.url, entity);
    }

    /**
     * @desc common function for updateing an entity
     * @param {T} entity
     * @param {string} id
     * @returns {Observable<T>}
     * @memberof BaseHttpProvider
     */
    public updateEntity(id: string, entity): Observable<T> {
        return this.http.patch<T>(this.url + '/' + id, entity);
    }

    /**
     * @desc common function for deleting an entity
     * @param {T} entity
     * @returns {Observable<any>}
     * @memberof BaseHttpProvider
     */
    public deleteEntity(entity: T): Observable<any> {
        return this.http.delete(this.url + '/' + entity.id, { responseType: "text" });
    }

    /**
     * @desc common function for getting all the entities
     * @returns {Observable<T>}
     * @memberof BaseHttpProvider
     */
    public fetchEntities(searchQuery?: string): Observable<Array<T>> {
        const searchQueryEncoded = searchQuery ? encodeURIComponent(searchQuery) : "";
        return this.http.get<Array<T>>(this.url + "?query=" + searchQueryEncoded);
    }

}
