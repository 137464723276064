import { FormDialogComponent } from './../common/form-dialog/form-dialog.component';
import { FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';

export abstract class BaseEntityCreate {
    private backRoute: string;

    constructor(
        protected dialog: MatDialog,
        protected router: Router,
        back: string
    ) {
        this.backRoute = back;
    }

    /**
     * @desc navigate to the previous page
     * @param {FormGroup} [form]
     * @returns
     * @memberof BaseEntityCreate
     */
    public goBack(form?: FormGroup) {
        if (!form || !form.dirty) {
            this.router.navigateByUrl(this.backRoute);
            return;
        }

        FormDialogComponent.route = this.backRoute;
        this.dialog.open(FormDialogComponent, { autoFocus: false });
    }
}
