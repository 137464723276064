import { Injectable } from "@angular/core";
import { MatSnackBar } from "@angular/material/snack-bar";

@Injectable({
    providedIn: 'root'
})
export class UtilsService {

    public isDirty: boolean;

    constructor(
        private snackBar: MatSnackBar,
    ) {

    }

    /**
     * @desc cleanup app id from role
     * @param {string} role
     * @returns
     * @memberof UtilsService
     */
    getShortRole(role: string) {
        return role ? role.slice(41) : "";
    }

    /**
     * @desc show simple snackBar with custom message
     * @param {string} message
     * @memberof UtilsService
     */
    displayToast(message: string) {
        this.snackBar.open(message, "Got it", {
            duration: 12000
        });
    }
}
