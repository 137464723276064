<div class="attentionIconWrapper">
    <div class="atentionIcon">
    </div>
</div>
<h3 mat-dialog-title> {{ 'messages.wantBack' | translate }} </h3>
<div mat-dialog-content>
    <div> {{ 'messages.stayOrLeave' | translate }}</div>
</div>
<div mat-dialog-actions>
    <button class="coloredButton" (click)="onYes()">{{ 'messages.ok' | translate }}</button>
    <button class="copyButton" (click)="onCancel()">{{ 'messages.cancel' | translate }}</button>
</div>
