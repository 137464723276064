import * as tslib_1 from "tslib";
import { BaseEntityCreate } from 'src/app/base/base.entity.create';
var BaseEntityEdit = /** @class */ (function (_super) {
    tslib_1.__extends(BaseEntityEdit, _super);
    function BaseEntityEdit(dialog, router, backRoute) {
        var _this = _super.call(this, dialog, router, backRoute) || this;
        _this.dialog = dialog;
        _this.router = router;
        return _this;
    }
    return BaseEntityEdit;
}(BaseEntityCreate));
export { BaseEntityEdit };
