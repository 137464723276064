import { MatSnackBar } from "@angular/material/snack-bar";
import * as i0 from "@angular/core";
import * as i1 from "@angular/material/snack-bar";
var UtilsService = /** @class */ (function () {
    function UtilsService(snackBar) {
        this.snackBar = snackBar;
    }
    /**
     * @desc cleanup app id from role
     * @param {string} role
     * @returns
     * @memberof UtilsService
     */
    UtilsService.prototype.getShortRole = function (role) {
        return role ? role.slice(41) : "";
    };
    /**
     * @desc show simple snackBar with custom message
     * @param {string} message
     * @memberof UtilsService
     */
    UtilsService.prototype.displayToast = function (message) {
        this.snackBar.open(message, "Got it", {
            duration: 12000
        });
    };
    UtilsService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function UtilsService_Factory() { return new UtilsService(i0.ɵɵinject(i1.MatSnackBar)); }, token: UtilsService, providedIn: "root" });
    return UtilsService;
}());
export { UtilsService };
