import { Subscription } from 'rxjs';
import { Subject } from 'rxjs';
import { Router } from '@angular/router';
import { MatTableDataSource } from '@angular/material/table';

export abstract class BaseList {
    dataSource: MatTableDataSource<any> = new MatTableDataSource([]);
    displayedColumns: Array<string>;
    filterValue: string;

    //search with debounce time
    protected searchTextChanged = new Subject<string>();
    protected searchTextSubscription: Subscription;

    private sortingColumn: string;
    private editRoute: string;
    protected isLoading: boolean = true;

    constructor(
        protected router: Router,
        protected route: string,
        protected sortCol: string
    ) {
        this.editRoute = route;
        this.sortingColumn = sortCol;
    }

    /**
     * @desc pass the id to the edit screen
     * @param {string} id
     * @memberof BaseList
     */
    public edit(id: string) {
        this.router.navigate([this.editRoute, id]);
    }

    /**
     * @desc simple filtering
     * @param {string} filterValue
     * @memberof BaseList
     */
    public filter(filterValue: string): void {
        this.filterValue = filterValue;
        this.searchTextChanged.next(filterValue);
    }

    /**
     * @protected
     * @desc common sorting
     * @param {any} a
     * @param {any} b
     * @returns {number}
     * @memberof BaseList
     */
    protected sortStrings(a: any, b: any): number {
        const nameA = a[this.sortingColumn],
            nameB = b[this.sortingColumn];
        if (nameA < nameB) return -1;
        if (nameA > nameB) return 1;
        return 0;
    }

    /**
     * @desc check if data source is empty
     * @returns {boolean}
     * @memberof BaseList
     */
    public isListEmpty(): boolean {
        return this.dataSource.filteredData.length === 0 ? true : false;
    }

    /**
     * @returns
     * @desc check if is loading
     * @memberof BaseList
     */
    public showLoading() {
        return this.isLoading;
    }
}
