<div class="myWrapper">
  <div class="titleWrapper">
    <div class="title">{{ 'messages.inviteUserTitle' | translate }} </div>
  </div>
  <div *ngIf="isLoading" class="spinnerWrapper">
    <mat-spinner></mat-spinner>
  </div>
  <div class="bottomWrapper">
    <div class="mainWrapper">
      <div class="leftWrapper">
        <form class="example-form" [formGroup]="invitationForm" (ngSubmit)="onSubmit()">
          <mat-form-field class="basicInput">
            <input formControlName="firstName" required matInput placeholder="{{ 'messages.firstName' | translate }} ">
            <mat-error
              *ngIf="invitationForm.get('firstName').hasError('required') || invitationForm.get('firstName').hasError('invalidInput')">{{
                    'errors.requiredFirstName' | translate }}</mat-error>
          </mat-form-field>
          <br />
          <mat-form-field class="basicInput">
            <input formControlName="lastName" required matInput placeholder="{{ 'messages.lastName' | translate }} ">
            <mat-error
              *ngIf="invitationForm.get('lastName').hasError('required') || invitationForm.get('lastName').hasError('invalidInput')">{{
                    'errors.requiredLastName' | translate }}</mat-error>
          </mat-form-field>
          <br />
          <mat-form-field class="basicInput">
            <input formControlName="email" required matInput placeholder="{{ 'messages.email' | translate }} ">
            <mat-error
              *ngIf="invitationForm.get('email').hasError('required') || invitationForm.get('email').hasError('email')">{{
                    'errors.requiredEmail' | translate }}</mat-error>
          </mat-form-field>

          <mat-form-field class="basicInput">
            <mat-select class="selector"
              placeholder="{{ 'messages.application' | translate }}">
              <mat-option *ngFor="let option of applicationOptions" (onSelectionChange)="onChangeApplicationOption($event, option)"
                [value]="option">
                {{option | translate}}
              </mat-option>
            </mat-select>
          </mat-form-field>

          <div *ngIf="applicationRoles?.length > 0">
          <mat-form-field class="basicInput">
            <mat-select class="selector"
              placeholder="{{ 'messages.applicationRoles' | translate }}">
              <mat-option *ngFor="let option of applicationRoles" (onSelectionChange)="onChangeRole($event, option)"
                [value]="option">
                {{option | translate}}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>

        </form>

        <div *ngIf="displayErrorMessage.length" class="errorMessage">
          <div>{{displayErrorMessage}}</div>
          <i id="lowerCloseIcon" class="material-icons" matSuffix (click)="resetErrorMessage()">close</i>
      </div>
      
        <div class="submitWrapper">
          <button (click)="onSubmit()" [ngClass]="{'disabledButton': invitationForm.invalid}"
            [disabled]="invitationForm.invalid" class="coloredButton">{{ 'messages.inviteUser' | translate }} </button>
        </div>
      </div>
    </div>
  </div>
</div>