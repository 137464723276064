import { Subscription } from "rxjs";
import { debounceTime } from "rxjs/internal/operators/debounceTime";
import { BaseList } from "src/app/base/base.list";
import { ApplicationService } from "src/app/services/application.service";
import { UtilsService } from "src/app/services/utils.service";

import { Component, OnDestroy, OnInit, ViewChild } from "@angular/core";
import { MatPaginator } from "@angular/material/paginator";
import { MatSort } from "@angular/material/sort";
import { MatTableDataSource } from "@angular/material/table";
import { Router } from "@angular/router";
import { TranslateService } from "@ngx-translate/core";

const DISPLAYED_COLUMNS: Array<string> = [
    'displayName',
    'applicationId',
    'edit'
];

@Component({
    selector: 'app-applications',
    templateUrl: './applications.component.html',
    styleUrls: ['./applications.component.scss']
})
export class ApplicationsComponent extends BaseList
    implements OnInit, OnDestroy {
    @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
    @ViewChild(MatSort, { static: true }) sort: MatSort;

    private applicationSubscription: Subscription;
    private translations: any;
    role: string;
    isLoaded: boolean;

    constructor(
        private appService: ApplicationService,
        protected router: Router,
        private utils: UtilsService,
        private translate: TranslateService
    ) {
        super(router, './applications', 'displayName');
        this.initI18nData();
        this.role = localStorage.getItem('ccmsrole');
    }

    ngOnInit() {
        this.isLoaded = false;
        this.displayedColumns = DISPLAYED_COLUMNS;
        this.applicationSubscription = this.appService
            .getApplications(null)
            .subscribe(
                applications => {
                    // set data source related settings
                    this.dataSource = new MatTableDataSource(applications);
                    this.isLoading = false;
                    // By default sort the first columns by selected column
                    if (applications.length > 0) {
                        this.dataSource.filteredData.sort(
                            this.sortStrings.bind(this)
                        );
                        // pagination and sorting
                        this.dataSource.paginator = this.paginator;
                        this.dataSource.sort = this.sort;
                        this.isLoaded = true;
                    }
                },
                error => {
                    this.isLoading = false;
                    this.isLoaded = true;
                    this.utils.displayToast(this.translations.noDataFound);
                }
            );
        this.initSearchLogic();
    }

    private initSearchLogic() {
        this.searchTextSubscription = this.searchTextChanged
            .pipe(debounceTime(500))
            .subscribe(
                value => {
                    this.isLoading = false;
                    this.appService.getApplications(value as string);
                    this.dataSource.filter = value as string;
                },
                error => {
                    this.isLoading = false;
                    this.utils.displayToast(this.translations.noDataFound);
                }
            );
    }

    private initI18nData() {
        this.translate.get('errors').subscribe(data => {
            this.translations = data;
        });
    }

    ngOnDestroy() {
        this.applicationSubscription.unsubscribe();
    }
}
