<div class="container">
  <div class="error-template">
    <h2>403 Forbidden Page</h2>
    <div class="error-details">
      {{ 'errors.forbiddenPage' | translate }}
    </div>
    <div class="error-advise">
      <div>
        {{ 'errors.requestAdministratorAdvise' | translate }}
        <a style="color:#165787;" href="mailto:p0060003969@munichre.com">p0060003969@munichre.com</a>.
      </div>
      <div>
        {{ 'errors.changeAccountsAdvise' | translate }}
      </div>
    </div>
    <div class="error-actions">
      <button (click)="logout()" mat-raised-button color="primary"> {{ 'errors.changeAccounts' | translate }} </button>
    </div>
  </div>
</div>