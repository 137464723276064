import { Subscription } from "rxjs";
import { BaseEntityCreate } from "src/app/base/base.entity.create";
import { ValidateUrl } from "src/app/common/validators/url.validator";
import { ValidateWhiteSpace } from "src/app/common/validators/whitespace.validator";
import { ICreateApplication } from "src/app/models/ICreateApplication";
import { ApplicationService } from "src/app/services/application.service";
import { UtilsService } from "src/app/services/utils.service";

import { Component, OnDestroy, OnInit } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { MatDialog } from "@angular/material/dialog";
import { Router } from "@angular/router";
import { TranslateService } from "@ngx-translate/core";

@Component({
    selector: 'app-application-creation',
    templateUrl: './application-creation.component.html',
    styleUrls: ['./application-creation.component.scss']
})
export class ApplicationCreationComponent extends BaseEntityCreate
    implements OnInit, OnDestroy {
    appForm: FormGroup;

    isLoading: boolean = false;

    // error message displayed above forms
    displayErrorMessage: string = '';

    private translations: any;
    private appCreation: Subscription;
    public productOptions = new Array<string>(
        'ProductSuite',
        'APIProduct',
        'API'
    );
    public option: string;
    public environments = new Array<string>('DEV', 'INT', 'PRD');
    public environment: string;
    public customer: string = '';
    public customerDescription: string = '';
    public applicationApiName: string = '';

    private appToCreate: ICreateApplication;

    constructor(
        private appService: ApplicationService,
        protected router: Router,
        protected dialog: MatDialog,
        private formBuilder: FormBuilder,
        private translateService: TranslateService,
        private utilService: UtilsService
    ) {
        super(dialog, router, 'applications');
        this.initI18nData();
    }

    ngOnInit() {
        this.appForm = this.formBuilder.group({
            displayName: [
                '',
                [
                    Validators.required,
                    Validators.maxLength(90),
                    ValidateWhiteSpace
                ]
            ]
        });

        // check if form value has changed
        this.appForm.valueChanges.subscribe(val => {
            if (this.appForm.dirty) {
                this.utilService.isDirty = true;
            }
        });
    }

    onSubmit() {
        if (this.appForm.invalid) {
            this.getErrorMessage('errors.invalidApplication');
            return;
        }

        if (!this.appForm.get('displayName').value.startsWith('CCMS-')) {
            this.getErrorMessage('errors.invalidDisplayNameForApplication');
            return;
        }
        this.appToCreate = {
            displayName: this.appForm.get('displayName').value
        };
        this.isLoading = true;
        this.appCreation = this.appService
            .createApplication(this.appToCreate)
            .subscribe(
                response => {
                    this.isLoading = false;
                    this.utilService.isDirty = false;
                    this.utilService.displayToast(
                        this.translations['createdApplication']
                    );
                    this.goBack();
                },
                err => {
                    this.isLoading = false;
                    this.getErrorMessage('errors.couldNotCreateApplication');
                }
            );
    }


    // get translation for tips messages
    private getErrorMessage(message: any) {
        this.translateService.get(message).subscribe((text: string) => {
            this.displayErrorMessage = text;
        });
    }

    private initI18nData() {
        this.translateService.get('errors').subscribe(data => {
            this.translations = data;
        });
    }

    resetErrorMessage() {
        this.displayErrorMessage = '';
    }


    /** ON CHANGE DETECTION METHODS */
    onChangeProductType(event: any, selectedProductType: string) {
        if (event.isUserInput) {
            this.option = selectedProductType;
            this.setDisplayName();
        }
    }

    onChangeEnvironment(event: any, selectedEnvironment: string) {
        if (event.isUserInput) {
            this.environment = selectedEnvironment;
            this.setDisplayName();
        }
    }

    onChangeApplicationApiName(event: any, value: string) {
        this.applicationApiName = value;
        this.setDisplayName();
    }

    onChangeCustomer(event: any, value: string) {
        this.customer = value;
        this.setDisplayName();
    }

    onChangeDescription(event: any, value: string) {
        this.customerDescription = value;
        this.setDisplayName();
    }

    setDisplayName() {
        if (this.customerDescription && this.customerDescription !== '') {
            this.appForm
            .get('displayName')
            .setValue(
                `CCMS-${this.applicationApiName}-${this.customer}-${this.option}-${this.customerDescription}-${this.environment}`
            );
        } else {
            this.appForm
            .get('displayName')
            .setValue(
                `CCMS-${this.applicationApiName}-${this.customer}-${this.option}-${this.environment}`
            );
        }
    }

    ngOnDestroy() {
        if (this.appCreation) {
            this.appCreation.unsubscribe();
        }
    }
}
